@import '../../stylesheets/variables.scss';

.container {
	display: flex;
	flex-direction: column;
	margin: 24px 0;
}

.label {
	color: $c_text;
	font-size: 12px;
	font-weight: $fw_medium;
	line-height: 22px;
	margin: 0 0 8px;
}

.input {
	appearance: none;
	border-radius: 4px;
	border: 1px solid $c_gray_300;
	box-shadow: 0px 0px 0px 0px $c_gray_300;
	font-size: 14px;
	min-height: 40px;
	outline: 0;
	padding: 11px 12px;
	transition: $tr_fast;
	width: 100%;

	@media (min-width: 768px) {
		max-width: 380px;
	}

	&::placeholder {
		color: #c7cdd1;
		font-size: 14px;
	}

	&:focus {
		outline: 0;
		border: 1px solid $c_primary;
		box-shadow: 0px 0px 0px 1px $c_primary;
	}
}
