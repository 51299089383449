@import '../../stylesheets/variables.scss';

/* Customize the label (the container) */
.container {
	font-size: 12px;
	line-height: 24px;
	font-weight: 500;
	display: flex;
	align-items: center;
	position: relative;
	margin-bottom: 12px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	&.disabled {
		color: #7b7e93;
		cursor: not-allowed;
	}
}

/* Hide the browser's default radio button */
.container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom radio button */
.checkmark {
	position: relative;
	top: 0;
	left: 0;
	height: 16px;
	width: 16px;
	margin-right: 16px;
	background-color: $c_white;
	border-radius: 50%;
	border: 1px solid $c_checkbox_border;
	transition: all 0.15s;
}

input:disabled ~ .checkmark {
	background-color: rgba($c_checkbox_border, 0.25);
	cursor: not-allowed;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
	border: 1px solid $c_primary;
}

.container:hover input:disabled ~ .checkmark {
	border: 1px solid $c_checkbox_border;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
	background-color: $c_white;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
	display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
	top: 2px;
	left: 2px;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: $c_primary;
}
