@import '../../stylesheets/variables.scss';

.container {
	align-items: center;
	background: rgba(0, 0, 0, 0.5);
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	left: 0;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: 10;
}

.content {
	background: white;
	border-radius: 10px;
	box-shadow: 10px 0px 30px rgba(0, 0, 0, 0.25);
	box-sizing: border-box;
	height: auto;
	margin: auto auto;
	padding: 20px;
	width: 100%;
	max-width: calc(300px + 40px);
	font-size: 12px;
	color: $c_text;

	@media (min-width: 640px) {
		max-width: calc(300px + 134px);
		padding: 67px 67px 42px;
	}
}

.buttonContainer {
	margin: 20px 0 0 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.heading {
	font-size: 24px;
	line-height: 28px;
}

.hidden {
	// Could hide content altogether
}

.pushmeTimer {
	font-size: 18px;
	line-height: 22px;
	font-weight: $fw_medium;
	text-align: center;
	margin: 30px auto 30px;
}
