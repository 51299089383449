@import '../../stylesheets/variables.scss';

.formContainer {
	margin: 0 auto;
	max-width: 900px;
	width: 100%;
	text-align: left;
}

.form {
	align-items: center;
	display: flex;
	flex-direction: column;
}

.formErrorHeading {
	display: block;
	font-weight: 700;
}

.text {
	margin-bottom: 35px;
	text-align: left;
}

.errorMessage {
	margin-top: 0;
	width: 100%;
}

.hidden {
	display: none;
}

.enable2FAOnLoginContainer {
	display: flex;
	align-items: center;
}

.locked {
	color: #a3a3a3;
	opacity: 0.5;
	margin-left: 10px;
}

.toggleWrapper {
	width: auto;
	margin: 0;
}
