@import '../../stylesheets/variables.scss';

.container {
	margin-top: 30px;
	border: 1px solid $c_warning_dark;
	padding: 10px 20px;
	width: 100%;
	background: $c_warning_light;
	display: flex;
	border-radius: 4px;
	align-items: center;

	.icon {
		margin-right: 20px;
		border-radius: 50%;
		flex: 0 0 auto;
		color: white;
		background: $c_warning_dark;
		font-weight: $fw_bold;
		height: 20px;
		width: 20px;
		padding: 0;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
