@import './stylesheets/variables.scss';

.container {
	margin: auto;
	max-width: 1055px;
	width: 100%;
	padding: 0 10px 40px;

	@media (min-width: 520px) {
		padding: 0 20px 40px;
	}
}

.header {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 26px 20px;
}

.logo {
	height: auto;
	margin: 26px 26px 26px 0;
	max-width: 150px;
	max-height: 60px;
}

.userInfo {
	color: $c_text;
	font-size: 12px;
	font-weight: $fw_bold;
	line-height: 28px;
	display: flex;
	align-items: center;
	width: 100%;
	flex-wrap: wrap;

	@media (min-width: 768px) {
		width: auto;
	}
}

.username {
	flex: 1 1 auto;
	margin-right: 30px;
}

.logout {
	line-height: 28px;
	display: inline-block;
	min-height: 0px;
	padding: 0;
	width: auto;
	margin: 0;
}

.siteTitle {
	display: block;
	width: 100%;
	margin: 42px 0 26px;

	@media (min-width: 480px) {
		margin: 26px 0 26px;
	}
}

.body {
	background: $c_white;
	border-radius: 8px;
	// box-shadow: 0px 2px 6px #0000000f;
	box-shadow: 0 15px 40px rgba(#4d4f5c, 0.15);
	display: flex;
	margin: auto;
	flex-direction: column;

	@media (min-width: 768px) {
		flex-direction: row;
	}
}

.navigation {
	flex: 0 0 auto;
	width: auto;
	border-bottom: 2px solid rgba($c_gray_400, 0.3);

	@media (min-width: 768px) {
		width: 340px;
		border-right: 2px solid rgba($c_gray_400, 0.3);
		border-bottom: 0;
	}

	a {
		color: $c_black;
		display: block;
		font-size: 14px;
		font-weight: $fw_medium;
		line-height: 18px;
		min-height: 60px;
		padding: 20px;
		text-decoration: none;

		&.active {
			background: $c_gray_000;
			color: $c_primary;

			@media (min-width: 768px) {
				color: $c_black;
			}
		}

		@media (min-width: 768px) {
			padding: 21px 24px;
		}
	}
}

.main {
	padding: 20px;
	min-height: 450px;
	flex: 1 1 auto;

	@media (min-width: 768px) {
		padding: 40px;
	}
}
