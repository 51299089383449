@import '../../stylesheets/variables.scss';

.deviceTable {
  display: none;
  width: 100%;
  border-collapse: collapse;

  @media (min-width: 480px) {
    display: table;
  }

  @media (min-width: 768px) {
    position: relative;
    left: -29px;
    margin: 29px 0 44px 0;
    width: calc(100% + 29px);
  }

  tr {
    border-bottom: 2px solid $c_gray_000;
  }

  tbody tr {
  }

  thead tr {
    border-bottom: 0;
  }

  tbody tr:last-child {
    border-bottom: 0;
  }

  th {
    background: $c_gray_000;
    padding: 19px 29px;
  }

  td {
    padding: 13px 29px;
  }

  th,
  td {
    text-align: left;
  }

  tr th:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  tr th:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  tr th:last-child,
  tr td:last-child {
    text-align: right;
  }
}

.deviceList {
  @media (min-width: 480px) {
    display: none;
  }
}

.deviceListItemForList {
  margin: 20px 0;
  padding: 20px;
  border-radius: 8px;
  background: $c_gray_000;

  .nameContainer {
    display: flex;
    justify-content: space-between;
  }

  .name {
    font-size: 12px;
    font-weight: bold;
  }

  .twoFA {
    font-size: 11px;
    min-width: 60px;
    padding: 0 10px;

    &.inactive {
      background: darken($c_gray_000, 5%);
    }
  }

  .removeButton {
    margin: 20px auto 0;
    width: 100%;
    max-width: 1000px;
    min-height: 45px;
  }
}

.twoFA {
  min-width: 84px;
  display: block;
  text-align: center;
  border-radius: 50px;
  line-height: 22px;
  font-weight: $fw_bold;

  &.active {
    background: $c_button;
    color: #ffffff;
  }

  &.inactive {
    background: $c_gray_000;
    color: $c_text;
  }
}

.removeButton {
  min-height: 0;
  padding: 0;
  min-width: 0;
  width: auto;
}

.note {
  margin-top: 30px;
  border: 1px solid $c_warning_dark;
  padding: 10px 20px;
  width: 100%;
  background: $c_warning_light;
  display: flex;
  border-radius: 4px;
  align-items: center;

  .noteIcon {
    margin-right: 20px;
    border-radius: 50%;
    flex: 0 0 auto;
    color: white;
    background: $c_warning_dark;
    font-weight: $fw_bold;
    height: 20px;
    width: 20px;
    padding: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.confirmationModalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirmationImage {
  max-width: 142px;
  margin-bottom: 28px;
}

.confirmationTitle {
  font-size: 18px;
  text-align: center;
  font-weight: $fw_bold;
}

.confirmButton,
.cancelButton {
  max-width: none;
}