@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@import './variables.scss';
@import-normalize;

$teroxxFonts: (
  400: 'soehne-buch',
  500: 'soehne-kraftig',
  600: 'soehne-halbfett',
  700: 'soehne-dreiviertelfett'
);

@each $weight, $file in $teroxxFonts {
  @font-face {
    font-family: 'Söhne';
    src: url('https://assets.teroxx.world/Soehne_Font_11_2024/WOFF2/#{$file}.woff2') format('woff2');
    font-weight: $weight;
    font-style: normal;
    font-display: swap;
  }
}

* {
	box-sizing: border-box;
	@include variable(fontFamily, font-family, none);
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;

	&::-webkit-scrollbar {
		width: 7px;
	}

	&::-webkit-scrollbar:horizontal {
		height: 7px;
	}

	&::-webkit-scrollbar-track,
	&::-webkit-scrollbar-track:horizontal {
		background: $c_gray_300 0% 0% no-repeat padding-box;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb,
	&::-webkit-scrollbar-thumb:horizontal {
		background-color: $c_primary;
		border-radius: 10px;

		&:hover {
			background-color: $c_button_hover;
		}
	}
}

html,
body {
	background-color: $c_background;
	color: $c_text;
	font-size: 12px;
	font-weight: $fw_medium;
	line-height: 22px;
	margin: 0;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1 {
	color: $c_title;
	font-size: 20px;
	font-weight: $fw_bold;
	line-height: 24px;
}

h2 {
	color: $c_title;
	font-size: 16px;
	font-weight: $fw_bold;
	line-height: 19px;
	margin-bottom: 30px;
}
