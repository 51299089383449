@import '../../../stylesheets/variables.scss';

.heading {
	font-size: 24px;
	line-height: 28px;
}

.text {
	font-size: 12px;
	color: $c_text;
}

.formGrid {
	@media (min-width: 768px) {
		grid-template-columns: auto 1fr;
		align-items: center;
	}
}

.sectionHeading {
	margin-top: 2px;
	border-bottom: 4px solid #f8f8f8;
	padding-bottom: 6px;
	font-size: 22px;
	color: #a3a3a3;
	font-weight: 300;
}

.label {
	margin: 0 15px 0 0;
	white-space: nowrap;
}

.button {
	width: 100%;
}

.error {
	color: $c_red;
	border: 1px solid #e4b9c0;
	padding: 15px;
	text-align: center;
	background: #f2dede;
	margin: 0 0 15px;
}

.hidden {
	display: none !important;
}

.googleAuthenticatorRegistrationCode {
	font-size: 12px;
	font-weight: bold;
}

.qrContainer {
	text-align: center;
	margin-top: 10px;
}

.formError {
}

.input {
	margin: 0 auto;
}

.buttonContainer {
	margin: 20px 0 0 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
