@mixin variable($variable, $property, $fallback) {
  // Fallback for browsers that don't support css vars
  // @include variable(backgroundColor, background, none);
  #{$property}: $fallback;
  #{$property}: var(--#{$variable});
}

//colors
$c_primary: var(--c_primary);
$c_primary_hover: var(--c_primary_hover);

$c_title: #272f4d;
$c_text: var(--c_text);
$c_input_placeholder: var(--c_input_placeholder);
$c_background: #fbfbfd;
$c_warning_dark: #ffbe0d;
$c_warning_light: #fffdf7;

$c_red: var(--c_red);
$c_white: #ffffff;
$c_black: #000000;

$c_gray_000: #f8f8fa;
$c_gray_300: #e6e8f0;
$c_gray_400: #c5cdd1;

$c_button: var(--c_button);
$c_button_hover: var(--c_button_hover);
$c_button_disabled: var(--c_button_disabled);

$c_checkbox_border: #a4afb7;

// font-weight
$fw_regular: 400;
$fw_medium: 500;
$fw_bold: 700;

// transitions
$tr_fast: all 0.1s, visibility 0s;
$tr_default: all 0.3s ease, visibility 0s;
