@import '../../stylesheets/variables.scss';

.message {
	border-radius: 4px;
	border-style: solid;
	border-width: 1px;
	font-size: 14px;
	font-weight: $fw_medium;
	line-height: 22px;
	padding: 9px 12px;
	margin-bottom: 24px;

	&.SUCCESS {
		border-color: $c_primary;
		color: $c_primary;
	}

	&.ERROR {
		border-color: $c_red;
		color: $c_red;
	}
}
