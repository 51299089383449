@import '../../stylesheets/variables.scss';

.button {
	align-items: center;
	background: $c_primary;
	border-radius: 4px;
	border: 2px solid $c_primary;
	color: $c_white;
	cursor: pointer;
	display: inline-flex;
	font-size: 12px;
	font-weight: $fw_medium;
	justify-content: center;
	min-height: 45px;
	outline: none;
	padding: 0 15px;
	text-decoration: none;
	transition: $tr_fast;
	max-width: 205px;
	width: 100%;
	margin: 8px auto;
	font-weight: $fw_bold;

	&.primary {
		&:hover {
			background-color: $c_button_hover;
			border-color: $c_button_hover;
		}

		&:focus {
			background-color: $c_button_hover;
			border-color: $c_button_hover;
		}

		&:disabled {
			background-color: $c_button_disabled;
			border-color: $c_button_disabled;
			cursor: not-allowed;
		}
	}

	&.secondary {
		background: none;
		color: $c_primary;
		text-decoration: none;

		&:hover {
			border-color: $c_button_hover;
			color: $c_button_hover;
		}

		&:focus {
			border-color: $c_button_hover;
			color: $c_button_hover;
		}

		&:disabled {
			border-color: $c_button_disabled;
			color: $c_button_disabled;
			cursor: not-allowed;
		}
	}

	&.danger {
		background: $c_red;
		border: 2px solid $c_red;
		color: $c_white;
		text-decoration: none;

		&:hover {
			background-color: $c_red;
			border-color: $c_red;
		}

		&:focus {
			background-color: $c_red;
			border-color: $c_red;
		}

		&:active {
			background-color: $c_red;
			border-color: $c_red;
		}

		&:disabled {
			background-color: $c_button_disabled;
			border-color: $c_button_disabled;
			color: $c_white;
			cursor: not-allowed;
		}
	}

	&.link {
		font-weight: $fw_bold;
		background: transparent;
		border: none;
		color: $c_primary;

		&:hover {
			color: $c_button_hover;
		}

		&:focus {
			color: $c_button_hover;
		}

		&:disabled {
			color: $c_button_disabled;
			cursor: not-allowed;
		}
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.animation {
	animation: spin 1000ms infinite linear;
}
