@import '../../stylesheets/variables.scss';

.error {
	position: relative;
	display: block;
	width: 100%;
	height: 20px;
	font-size: 12px;
	color: $c_red;
	font-weight: 700;
	line-height: 20px;
}
