@import '../../stylesheets/variables.scss';

.container {
	align-items: center;
	color: $c_text;
	cursor: pointer;
	display: flex;
	font-size: 12px;
	font-weight: $fw_medium;
	justify-content: flex-start;
	margin: 0;
	user-select: none;
	width: 100%;
	margin: 25px 0;

	&.disabled {
		cursor: not-allowed;
	}
}

.label {
	min-width: 280px;
	color: $c_black;
}

.toggle {
	display: inline-block;
	flex: 0 0 auto;
	height: 20px;
	margin-right: 8px;
	position: relative;
	width: 36px;
}

.slider {
	background-color: $c_white;
	border-radius: 20px;
	border-style: solid;
	border-width: 1.5px;
	border-color: #b3b6c6;
	bottom: 0;
	cursor: pointer;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	transition: 0.4s ease-out;

	// Remove !important when checkbox styles are no longer global
	&::before {
		background-color: #d9dbe5 !important;
		background-image: none !important;
		border-radius: 50%;
		border: none !important;
		bottom: 1px !important;
		content: '';
		height: 16px !important;
		left: 1px !important;
		position: absolute !important;
		top: 1px !important;
		transition: 0.4s !important;
		width: 16px !important;
	}

	&.disabled {
		border-color: #b3b6c6;

		&::before {
			background-color: #d9dbe5 !important;
		}
	}

	&:hover {
		background: rgba($c_button_hover, 0.15);
	}

	&:not(.disabled) {
		&:hover,
		&:focus {
			&::before {
				background-color: $c_button !important;
			}
		}
	}
}

.textSlider {
	border: 0;
	color: $c_button;
	font-size: 16px;
	font-weight: 500;
	text-align: center;
	margin-left: 6px;

	// Remove !important when checkbox styles are no longer global
	&::before {
		content: none !important;
	}
}

.input {
	height: 0;
	margin: 0;
	opacity: 0;
	width: 0;

	&:checked + .slider::before {
		background-color: $c_button !important;
		transform: translateX(13px);
		margin: 0;
		left: 2px !important;
	}

	&:disabled + .slider {
		cursor: not-allowed;
	}
}
