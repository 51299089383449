@import '../../stylesheets/variables.scss';

.container {
	position: relative;
	white-space: nowrap;
	margin: 0 0 0 30px;
	width: 100px;

	@media (max-width: 768px) {
		position: absolute;
		right: 50px;
		top: 50px;
	}

	@media (max-width: 520px) {
		right: 40px;
	}
}

.icon {
	font-size: 17px;
	margin: 0 5px;
	transform: translateY(1px);
}

.selectedValue {
	color: black;
	font-size: 12px;
	line-height: 21px;
	font-weight: $fw_bold;
	cursor: pointer;

	&:hover {
		color: $c_primary;
	}

	&.active {
		color: $c_primary;
	}
}

.listWrapper {
	background: $c_white;
	border-radius: 10px;
	box-shadow: 0 20px 50px rgba(0, 0, 0, 0.16);
	opacity: 0;
	position: absolute;
	top: 36px;
	transform: translate(-4px, 42px);
	visibility: hidden;
	z-index: 1;
	right: 0px;
	top: 42px;

	&::after {
		border-color: transparent transparent $c_white transparent;
		border-style: solid;
		border-width: 0 20px 20px 20px;
		content: '';
		position: absolute;

		top: -15px;
		transition: transform 0.3s;
		z-index: -1;

		right: 20px;
	}

	&.visible {
		z-index: 10;
		opacity: 1;
		visibility: visible;
		transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
		transform: translate(-4px, 10px);
	}
}

.list {
	overflow: auto;
	list-style-type: none;
	padding: 16px 30px;
	margin: 0;
	column-count: 2;
}

.listItem {
	font-size: 12px;
	font-weight: $fw_bold;
	color: black;
	transition: $tr_default;
	cursor: pointer;
	padding: 4px 0;
	line-height: 20px;
	min-width: 100px;

	&:hover {
		color: $c_primary;
	}
}
